<template>
  <v-container class="window" style="margin-left:11%; margin-right:11%; width: 78%;">
    <v-data-table :headers="headers" :items="masterLiburdata" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Form Hari Libur</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" dark class="mb-2" v-bind="attrs" v-on="on" @click="reset">
                Tambah Hari Libur
              </v-btn>
            </template>
            <v-card>
              <v-toolbar color="primary" dark>{{ formTitle }}</v-toolbar>
              <v-card-text>
                <v-container>
                  <v-alert dense dismissible text type="warning" v-if="validation.tgl_libur">
                    {{ validation.tgl_libur[0] }}
                  </v-alert>
                  <v-alert dense dismissible text type="warning" v-if="validation.deskripsi_libur">
                    {{ validation.deskripsi_libur[0] }}
                  </v-alert>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.tgl_libur" single-line label="Tanggal Libur" readonly>
                        <template v-slot:append-outer>
                          <date-picker v-model="editedItem.tgl_libur" />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.deskripsi_libur" label="Deskripsi Libur"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios'
import DatePicker from "../../../components/DatePicker.vue";
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Tanggal Libur', value: 'tgl_libur' },
      { text: 'Deskripsi Libur', value: 'deskripsi_libur' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {},
    validation: [],
    masterLiburdata: []
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Hari libur' : 'Edit Hari Libur'
    },
    ...mapGetters({
      user: 'user',
    })
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    let uri = `getmasterlibur`;
    axios.get(uri).then(response => {
      this.masterLiburdata = response.data.data;
      // console.log(this.masterLiburdata)
    });
  },
  components: {
    DatePicker
  },
  methods: {
    reset() {
      this.validation = [];
      this.validation.splice(0);
    },
    editItem(item) {
      this.editedIndex = this.masterLiburdata.indexOf(item)
      this.editedItem = Object.assign({}, item)
      // console.log(this.editedItem);
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.masterLiburdata.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.$swal.fire({
        title: 'Apakah anda ingin menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Hapus'
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'delete',
            url: 'deleteharilibur',
            data: {
              id: this.editedItem.id_libur
            },
          })
            .then(() => {
              let uri = `getmasterlibur`;
              axios.get(uri).then(response => {
                this.masterLiburdata = response.data.data;
              });
              this.$swal.fire(
                'Sukses!',
                'Data Berhasil dihapus',
                'success'
              )
              this.closeDelete()
            })
            .catch(error => {
              console.log(error.response)
              this.$swal.fire(
                'Gagal!',
                'Data Gagal dihapus',
                'warning'
              )
            })
        }
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      if (this.editedIndex > -1) {
        this.$swal.fire({
          title: 'Apakah anda ingin mengupdate data ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Update'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'updateharilibur',
              data: {
                dataLibur: this.editedItem,
                id: this.editedItem.id_libur
              }
            })
              .then(() => {
                let uri = `getmasterlibur`;
                axios.get(uri).then(response => {
                  this.masterLiburdata = response.data.data;
                  // console.log(this.masterLiburdata)
                });
                this.$swal.fire(
                  'Sukses!',
                  'Data berhasil di update!',
                  'success'
                )
                this.close();
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Data Gagal diupdate',
                  'warning'
                )
              })
          }
        })
      } else {
        this.$swal.fire({
          title: 'Apakah anda ingin menambahkan data ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Tambah'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'simpanharilibur',
              data: {
                data_libur: this.editedItem,
                id: this.user.id
              }
            })
              .then(() => {
                let uri = `getmasterlibur`;
                axios.get(uri).then(response => {
                  this.masterLiburdata = response.data.data;
                });
                this.$swal.fire(
                  'Sukses!',
                  'Data berhasil di simpan!',
                  'success'
                )
                this.close();
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Data Gagal disimpan',
                  'warning'
                )
              })
          }
        })
      }
    },
  },
}
</script>
